import React from 'react'

const HomeBanner = (props) => (
    <section id="banner" className="major">
        <div className="inner">
            <header className="major">
                <h1 className='montserrat-thick'>Say hello to your new MSP</h1>
            </header>
            <div className="content">
                <p>We are an MSP team offering hardware and I.T services across multiple markets in the greater northwest, specializing in medical and dental office infrastructure and I.T. management.
                </p>
                {/* <ul className="actions">
                    <li><a href="#one" className="button scrolly special">Get Started</a></li>
                </ul> */}
            </div>
        </div>
    </section>
)

export default HomeBanner
